import React from 'react';

import { COLOR_SCHEME_NAME_TO_CLASS, ColorScheme } from '../../constants';
import { clsx } from '../../utils/utils';
import * as styles from './Button.module.scss';

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  children: React.ReactNode;
  type?: 'submit' | 'button';
  colorScheme: ColorScheme;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}

const Button = ({
  children,
  type,
  colorScheme,
  onClick,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  const whiteButton = colorScheme === 'darkAndWhite' || colorScheme === 'mid';

  return (
    <button
      onClick={onClick}
      type={type || 'button'}
      className={clsx(
        styles.button,
        className,
        COLOR_SCHEME_NAME_TO_CLASS[colorScheme],
        whiteButton && styles.white,
      )}
      {...restProps}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
