export const AUTHOR_NAME = 'Northpoint';
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = ['en'] as const;

export const SCRIPTS_TO_RUN_AFTER_COOKIES_ACCEPT = ['googleAnalytics', 'googleTagManager'] as const;
export const USE_COOKIE_BAR = SCRIPTS_TO_RUN_AFTER_COOKIES_ACCEPT.length > 0;

export type ColorScheme =
  | 'white'
  | 'darkAndWhite'
  | 'darkAndTeal'
  | 'mid'
  | 'teal'
  | 'grey'
  | 'purple';

export const COLOR_SCHEME_NAME_TO_CLASS = {
  white: 'color-scheme-white',
  darkAndWhite: 'color-scheme-darkAndWhite',
  darkAndTeal: 'color-scheme-darkAndTeal',
  mid: 'color-scheme-mid',
  teal: 'color-scheme-teal',
  grey: 'color-scheme-grey',
  purple: 'color-scheme-purple',
};

// WARNING When updating here update in cms deskStructure too
// WARNING When updating here update in create_redirects_file_from_cms too
export const SERVICE_PREFIX_URL = 'service';
export const NEWS_ARTICLE_PREFIX_URL = 'news';

export const DEFAULT_SITE_DOMAIN = 'northpoint.org.uk';
export const DEFAULT_SITE_BASE_URL = 'https://www.northpoint.org.uk';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;
