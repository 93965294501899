import React, { useEffect, useState } from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  startsOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  withTransparentBackground?: boolean;
}

const Accordion = ({
  title,
  children,
  startsOpen,
  onOpen,
  onClose,
  withTransparentBackground,
}: AccordionProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  const bodyMaxHeight = isAccordionOpen && bodyRef ? bodyRef.scrollHeight : 0;

  useEffect(() => {
    if (!startsOpen) {
      setIsAccordionOpen(false);
    }
  }, [startsOpen]);

  return (
    <div
      className={clsx(
        styles.accordion,
        isAccordionOpen && styles.accordionOpen,
        withTransparentBackground && styles.withTransparentBackground,
      )}
    >
      <div className={styles.accordionBox}>
        <button
          className={styles.titleBox}
          aria-expanded={isAccordionOpen}
          aria-disabled="false"
          role="button"
          tabIndex={0}
          onClick={() => {
            setIsAccordionOpen(!isAccordionOpen);
            if (isAccordionOpen) {
              if (onClose) {
                onClose();
              }
            } else {
              if (onOpen) {
                onOpen();
              }
            }
          }}
        >
          <span className={styles.title}>{title}</span>
          <div className={styles.iconContainer}>
            <div className={styles.iconVerticalLine}></div>
            <div className={styles.iconHorizontalLine}></div>
          </div>
        </button>
        <div
          ref={newRef => {
            setBodyRef(newRef);
          }}
          className={clsx(styles.body, !isAccordionOpen && styles.hidden)}
          style={{ maxHeight: bodyMaxHeight }}
          role="region"
          aria-hidden={!isAccordionOpen}
        >
          <div className={styles.content}>{children}</div>
        </div>
        <noscript>
          <div
            className={clsx(styles.body, !isAccordionOpen && styles.hidden)}
            role="region"
            aria-hidden={!isAccordionOpen}
          >
            <div className={styles.content}>{children}</div>
          </div>
        </noscript>
      </div>
    </div>
  );
};

export default Accordion;
